.wishlist {
  margin-bottom: 3rem;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    .wishlist_title {
      color: #000;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
    }
    .btn-move-all-to-bag {
      display: flex;
      padding: 0.6rem 3rem;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }
  .content-wishlist {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}
.just-for-you {
  margin-bottom: 3rem;
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    .heading-title {
      color: #000;
      text-align: center;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.625rem;
      position: relative;
      padding-left: 2rem;
      line-height: 2.25rem;
      &::after {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 1.25rem;
        height: 100%;
        background-color: #db4444;
        border-radius: 4px;
      }
    }
    .btn-see-all {
      display: flex;
      padding: 0.6rem 3rem;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
    }
  }
  .content-just-for-you {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .wishlist {
    .heading {
      margin-bottom: 1.75rem;
    }
    .content-wishlist {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }
  }
  .just-for-you {
    margin-top: 3rem;
    .heading {
      margin-bottom: 1.75rem;
    }
    .content-just-for-you {
      grid-template-columns: repeat(2, 1fr);
      gap: 0.5rem;
    }
  }
  .btn-see-all,
  .btn-move-all-to-bag {
    padding: 0.6rem 1rem !important;
  }
}
