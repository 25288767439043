.content-quantity {
  text-align: center;
  vertical-align: middle;
  button {
    cursor: pointer;
    padding: 0 0.8rem;
    border: 1px solid #69696921;
  }
  button:first-child {
    border-radius: 15px 0 0 15px;
    color: #000;
    border-right: none;
  }

  button:last-child {
    border-radius: 0 15px 15px 0;
    color: #000;
    border-left: none;
  }
  input {
    width: 50%;
    border: 1px solid #69696921;
    border-left: none;
    border-right: none;
    border-radius: 0;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    padding: 10px;
    text-align: center;
  }
}
