@font-face {
  font-family: "Bootstrap Icons";
  src: url("https://cdn.jsdelivr.net/npm/bootstrap-icons/font/fonts/bootstrap-icons.woff2")
      format("woff2"),
    url("https://cdn.jsdelivr.net/npm/bootstrap-icons/font/fonts/bootstrap-icons.woff")
      format("woff");
}

.rating-container {
  .not-rating {
    .img-star {
      width: 70px;
      margin-bottom: 1rem;
    }
    span {
      font-size: 1rem;
      color: #000;
    }
  }
  .number-rating {
    .rating-summaty {
      margin-bottom: 1rem;
      .rating-poin {
        font-size: 2rem;
        color: #000;
        font-weight: bold;
        text-align: center;
        margin-right: 1rem;
        width: 25%;
      }
      .icon-star {
        font-size: 1.8rem;
      }
      .rating-total {
        font-size: 0.9rem;
        color: #707070;
      }
    }
    .rating-detail {
      .rate-content {
        align-items: center;
        .rate-star {
          font-size: 1.1rem;
          width: 30%;
        }
        .rate-bar {
          width: 50%;
          height: 8px;
          .progress-bar {
            background-color: #707070;
          }
        }
        .rate-time {
          width: 10%;
          margin-left: 20px;
          color: #707070;
          font-size: 0.8rem;
        }
      }
    }
  }
  .all-img-reviews {
    .title-img-review {
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }
    .img-review-contents {
      display: flex;
      .items {
        width: 110px;
        height: 110px;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 1rem;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:nth-child(n + 7) {
          display: none;
        }
        &:nth-child(6)::before {
          opacity: 0.5;
        }
        &:nth-child(6) {
          margin-right: 0;
        }
        &::before {
          position: absolute;
          content: "\f62c";
          font-size: 1.5rem;
          display: flex;
          font-family: "Bootstrap Icons";
          align-items: center;
          justify-content: center;
          color: white;
          background-color: #000;
          opacity: 0;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          transition: all 0.3s ease-in-out;
        }

        &:hover::before {
          opacity: 0.3;
        }
      }
    }
    .list-filter-reviews {
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .items {
        border-radius: 20px;
        background-color: #fff4f4;
        height: 40px;
        padding: 0.5rem 0.8rem;
        font-size: 1rem;
        color: #000;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        cursor: pointer;
        user-select: none;
        &:not(:last-child) {
          margin-right: 1rem;
        }
        &.active {
          color: #db4444;
          border: 1px solid #db4444;
        }
      }
    }
  }
  .reviewer {
    .reviewer-heading {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      .reviewer-name {
        margin: 0;
        margin-left: 0.8rem;
        color: #000;
        font-size: 1rem;
      }
    }
    .review-time,
    .review-options,
    .reviewer-rating {
      strong {
        font-weight: 500;
        font-size: 0.8rem;
        position: relative;
        &::before {
          position: absolute;
          content: ":";
          font-weight: bold;
          right: 0.7rem;
        }
      }
      .reviewer-right {
        font-size: 0.8rem;
      }
      .reviewer-star {
        font-size: 1rem;
      }
    }
  }
  .review-content {
    .review-img-lists {
      display: flex;
      align-items: center;
      .items {
        width: 110px;
        height: 110px;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 1rem;
        position: relative;
        &::before {
          position: absolute;
          content: "\f62c";
          font-size: 1.5rem;
          display: flex;
          font-family: "Bootstrap Icons";
          align-items: center;
          justify-content: center;
          color: white;
          background-color: #000;
          opacity: 0;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          transition: all 0.3s ease-in-out;
        }
        &:hover::before {
          opacity: 0.5;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .review-text {
      font-size: 0.8rem;
      color: #575454;
    }
  }
  .boder-row {
    position: relative;
    padding: 2rem 0;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #7070707c;
      z-index: 2;
      left: 0;
      top: 0;
    }
    .rep-comment {
      .avt-shop {
        margin-right: 1rem;
      }
      .rep-comment-content {
        background-color: #dfdcdc36;
        padding: 1rem;
        border-radius: 6px;
        .rep-heading {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;
          .name-shop {
            font-size: 0.9rem;
            margin-bottom: 0;
            margin-right: 8px;
          }
          .icon-check {
            color: #0b74e5;
          }
        }
        .rep-comment-text {
          font-size: 0.8rem;
          color: #575454;
        }
      }
    }
  }
}
