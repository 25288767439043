.login_form {
  min-height: calc(100vh - 365px - 130px - 25px);
  display: flex;
  align-items: center;
}

.list-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
