.user {
  position: relative;
  margin-left: 0.9rem;
  cursor: pointer;
  &:hover .list-user-contents {
    display: block;
    animation: fadeIn 1s ease;
  }
  &::before {
    position: absolute;
    content: "";
    left: -4rem;
    top: 1rem;
    width: 8rem;
    height: 4rem;
    background-color: transparent;
    display: none;
  }
  &:hover::before {
    display: block;
  }
  .user-avt {
    width: 35px;
    height: 35px;
  }
  .list-user-contents {
    list-style: none;
    padding: 0.5rem 0;
    margin: 0;
    width: max-content;
    position: absolute;
    right: 0;
    top: calc(100% + 1rem);
    z-index: 999;
    background: #f5f5f5;
    -webkit-backdrop-filter: blur(75px);
    backdrop-filter: blur(20px);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 4px;
    overflow: hidden;
    display: none;
    .user-contents-items {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background-color: #fafafafa;
        a {
          color: #db4444;
        }
      }
      a {
        transition: all 0.3s;
        text-decoration: none;
        color: #000;
      }
      .icon-user {
        margin-right: 8px;
      }
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
