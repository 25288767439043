.category-sidebar {
  border-top: 1px solid #afafaf;
  padding-top: 1rem;
  .sidebar-heading {
    font-size: 1.25rem;
    color: #000;
    font-weight: 400;
    i {
      font-size: 1.25rem;
      color: #000;
      font-weight: bold;
      margin-right: 8px;
    }
  }
  .list-product-categories {
    list-style: none;
    padding: 0;
    margin-top: 1rem;
    .item-categories {
      margin-bottom: 1rem;
      a {
        font-size: 1rem;
        font-weight: 400;
        text-decoration: none;
        color: #000;
        transition: all 0.3s;
      }
      &:hover a {
        color: #db4444;
      }
    }
    .item-categories.active {
      a {
        color: red;
      }
    }
  }
  .price-filter {
    border-top: 1px solid #afafaf;
    padding-top: 1rem;
    .heading-price-filter {
      span {
        font-size: 1.25rem;
        color: #000;
        font-weight: 400;
      }
    }
    .price-slider {
      color: #db4444;
    }
  }
  .sort-by-ratings {
    font-size: 1.25rem;
    color: #000;
    font-weight: 400;
    border-top: 1px solid #afafaf;
    padding-top: 1rem;
    margin-top: 1rem;
    .list-sort-by-ratings {
      list-style: none;
      padding: 0;
      margin: 1rem 0;
      .link-retings {
        font-size: 1rem;
        font-weight: 400;
        text-decoration: none;
        color: #000;
        transition: all 0.3s;
        display: inline-flex;
        cursor: pointer;
        &:hover {
          color: #db4444;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          padding: 2px 10px;
          border-radius: 20px;
        }
      }
      .link-retings.active {
        color: #db4444;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        padding: 2px 10px;
        border-radius: 20px;
      }
    }
  }
  .product-filter {
    border-top: 1px solid #afafaf;
    padding-top: 1rem;
    margin-top: 1rem;
    font-size: 1.25rem;
    color: #000;
    font-weight: 400;
    .list-product-filter {
      list-style: none;
      margin-top: 1rem;
      .item-product-filter {
        font-size: 1rem;
        font-weight: 400;
        text-decoration: none;
        color: #000;
        transition: all 0.3s;
      }
    }
  }
}
