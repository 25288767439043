.sidebar-heading,
.menu-mobile-icon {
  display: none;
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .category-product {
    .heading-title {
      display: none;
    }
  }
  .sidebar-heading,
  .menu-mobile-icon {
    display: block;
    i {
      font-size: 1.2rem;
    }
  }
  .list-products-category {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 0.5rem;
  }
  .left-turn {
    .category-sidebar {
      display: none !important;
    }
  }
  .category-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    background: black;
    z-index: 999;
    height: 100vh;
    overflow: scroll;
    padding: 1rem 3rem 1rem 2rem;
    .product-filter,
    .sort-by-ratings,
    .list-sort-by-ratings legend,
    .price-filter .heading-price-filter span,
    .list-product-categories li a,
    .sidebar-heading {
      color: white !important;
      i {
        color: white !important;
      }
    }
    .list-sort-by-ratings {
      .rating-review span {
        color: #faaf00;
      }
    }
    .product-filter .list-product-filter li {
      color: white !important;
      span {
        color: white !important;
      }
    }
  }
  .bg-category-sidebar {
    position: fixed;
    content: "";
    background-color: #00000054;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: none;
  }
  .sidebar-heading {
    position: relative;
    .icon-close-mobile {
      position: absolute;
      top: -1.4rem;
      font-size: 2rem !important;
      right: -3rem;
    }
  }
}
