.link_bread {
  color: rgba($color: #000000, $alpha: 0.5);
  &:hover {
    color: #db4444;
    text-decoration: none;
  }
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
}
