.rating-hover-text {
  display: flex;
  align-items: center;
  .rating-review {
    margin-left: 1rem;
    font-size: 2rem;
    label {
      margin-bottom: 0;
    }
  }
} /* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .rating-hover-text {
    justify-content: start;
    .rating-review {
      margin-left: 0;
      font-size: 1.5rem;
    }
  }
}
