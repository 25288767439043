.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.product-card {
  position: relative;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .image-container {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    object-fit: cover;

    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .favorite-button {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      &::before {
        font-size: 0.8rem;
        white-space: nowrap;
        content: "Yêu thích";
        position: absolute;
        right: 3rem;
        background-color: #000;
        color: #fff;
        padding: 3px 8px;
        border-radius: 4px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      &:hover::before {
        visibility: visible;
        opacity: 1;
      }
    }
    .add-to-cart-button {
      position: absolute;
      top: 2.25rem;
      right: 0;
      border: none;
      &::before {
        font-size: 0.8rem;
        white-space: nowrap;
        content: "Thêm vào giỏ hàng";
        position: absolute;
        right: 3rem;
        background-color: #000;
        color: #fff;
        padding: 3px 8px;
        border-radius: 4px;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease;
      }
      &:hover::before {
        visibility: visible;
        opacity: 1;
      }
    }

    &:hover {
      .add-to-cart-container {
        visibility: visible;
        opacity: 1;
      }
    }
    .discount-badge {
      position: absolute;
      top: 10px;
      left: 10px;
      background-color: red;
      padding: 5px 10px;
      border-radius: 5px;
    }

    .discount-badge-text {
      color: white;
      font-weight: bold;
    }
  }

  .add-to-cart-container {
    position: absolute;
    height: 40px;
    bottom: 0px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s;

    button {
      background: #000;
      color: #fff;
      border: none;
      width: 100%;
      cursor: pointer;
    }
  }

  .item-title {
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      color: #db4444;
    }
    .product-link {
      display: inline-block;
      width: 188px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .price_container {
    display: flex;
    justify-content: space-between;

    .current_price {
      color: #db4444;
    }

    .original_price {
      text-decoration: line-through;
      color: #6b6b6b;
    }
  }
  .product-link {
    color: inherit;
    text-decoration: none;
  }
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
}
