.manage-my-account {
  .list-contents {
    list-style: none;
    padding: 0;
    .item-contents {
      display: grid;
      grid-template-columns: 20% 80%;
      align-items: center;
      margin-bottom: 1.5rem;
      .content-left {
        font-size: 1rem;
        color: #000;
      }
      .content-right {
        font-size: 1rem;
        color: #000;
        display: flex;
        .radio-content {
          display: inline-block;
        }
        .input-name {
          padding: 0.5rem;
          width: 100%;
        }
        div:not(:last-child) {
          margin-right: 1rem;
        }
        .input-birthday {
          background-color: #f5f5f5;
          padding: 0 8px;
        }
      }
    }
  }
  .my-account {
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .heading {
      text-align: center;
      margin-bottom: 1rem;
      border-bottom: 1px solid #464646;
    }
  }
  .add-address {
    margin-top: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid #464646;
    }
    .my-address {
      display: grid;
      grid-template-columns: 80% 20%;
      .my-address-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .label-input {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          cursor: pointer;
          color: #000;
          .radio-circle {
            display: inline-block;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            margin-right: 8px;
            vertical-align: middle;
            transition: background-color 0.3s, border-color 0.3s;
            position: relative;
            z-index: 1;
            border: 1px solid #464646;
          }
          .input-choose-default {
            display: none;
            &:checked + .radio-circle {
              border: 1px solid #db4444;
            }

            &:checked + .radio-circle::before {
              content: "";
              display: inline-block;
              width: 13px;
              height: 13px;
              background-color: #db4444;
              border-radius: 50%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: -1;
            }
          }
        }
        button {
          padding: 0.3rem 2rem;
          transition: all 0.3s;
          display: flex;
          align-items: center;
          .icon-address {
            font-size: 1.3rem;
            transition: all 0.3s ease;
          }
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          &:hover {
            background-color: #db4444;
            color: white;
          }
          &:hover .icon-address {
            color: white;
          }
        }
      }
    }
  }
}
.label-input {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  cursor: pointer;
  color: #000;
  .radio-circle {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative;
    z-index: 1;
    border: 1px solid #464646;
  }
  .input-choose-default {
    display: none;
    &:checked + .radio-circle {
      border: 1px solid #db4444;
    }

    &:checked + .radio-circle::before {
      content: "";
      display: inline-block;
      width: 13px;
      height: 13px;
      background-color: #db4444;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .manage-my-account {
    .heading {
      padding-bottom: 1rem;
      margin-bottom: 2rem;
      .title {
        margin-bottom: 0;
        font-size: 1.3rem;
      }
    }
    .eidt-avt {
      margin-bottom: 2rem;
    }
    .list-contents {
      .item-contents {
        grid-template-columns: 35% 65%;
        align-items: unset;
      }
    }
    .add-address {
      .save-account {
        padding: 5px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
      }
      .my-address {
        grid-template-columns: 1fr;
        .my-address-right {
          align-items: unset;
        }
      }
    }
  }
}
