header.header-home {
  .header-top {
    background-color: #000000;
    line-height: 1rem;
    padding: 1rem 0;
    .text-sale {
      color: #fafafa;
      font-size: 0.8rem;
    }
    a {
      color: #fafafa;
      text-decoration: underline;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .replacce-header-nav {
    height: 0px;
  }
  .replacce-header-nav.active {
    height: 97px;
  }
  .header-nav {
    border-bottom: 1px solid #0000001e;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 1.5rem 0 1rem;
    .nav-content {
      .oen_menu_icon {
        display: none;
      }
      .link-logo {
        color: #000000;
        font-weight: bold;
        font-size: 1.5rem;
        display: inline-block;
        text-decoration: none;
      }
      .list-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        li.item:not(:last-child) {
          margin-right: 3rem;
        }
        li.item {
          position: relative;
          padding-bottom: 5px;
          a {
            color: #000000;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: 1.5rem;
            text-decoration: none;
            transition: all 0.3s ease-in;
          }

          &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 0;
            height: 2px;
            background-color: #db4444;
            opacity: 0.5;
            transition: all 0.3s ease;
          }
          &:hover a {
            color: #db4444;
          }
          &:hover::after {
            width: 100%;
          }
        }
      }
      .nav-left {
        .list-menu-icon {
          list-style: none;
          margin: 0;
          margin-left: 1.5rem;
          padding: 0;
          .item {
            .customized-badge {
              .MuiBadge-badge {
                position: absolute;
              }
            }
            a i {
              font-size: 1rem;
              color: #000000;
            }
          }
          .item:not(:first-child) {
            margin-left: 1rem;
          }
        }
      }
    }
  }
  .header-nav.active {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 999;
    backdrop-filter: blur(30px);
    animation: slideDown 0.35s ease-out;
  }
}

// Reposive

/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
  header.header-home {
    .header-nav .nav-content {
      .oen_menu_icon {
        display: block;
        svg {
          font-size: 1.75rem;
        }
      }
      .destop_navbar {
        .list-menu {
          display: none !important;
        }
      }
      .list-menu {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #000000;
        flex-direction: column;
        align-items: flex-start !important;
        width: 50vw;
        padding: 1rem;
        z-index: 999;
        .item a {
          color: white !important;
        }
      }
    }
  }
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .header-top {
    display: none;
  }
  .header-home {
    .header-nav {
      padding: 0 !important;
      .nav-content {
        > h1 {
          line-height: inherit;
        }
        .nav-left {
          .list-menu-icon {
            margin-left: 0.5rem !important;
            .item {
              &:not(:first-child) {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }
}
.menu-mobile-show {
  .list-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 0;
    min-width: 50vw;
    list-style: none;
    margin: 1rem 0;
    .item {
      padding: 0.5rem 1rem;
      transition: all 0.3s ease;
      width: 100%;
      a {
        color: #000000;
        text-decoration: none;
        transition: all 0.3s ease;
      }
      &:hover {
        background-color: #db4444;
      }
      &:hover a {
        color: white;
      }
    }
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0.6;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
