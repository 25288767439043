.my-reviews {
  .title {
    margin-bottom: 2rem;
  }
  .review-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
    .bottom-btn {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .shop-service {
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
  }
} /* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .my-reviews {
    .title {
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
    .review-bottom {
      flex-direction: column;
      justify-content: space-between;

      .shop-service {
        margin-bottom: 1.5rem;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .rating-hover-text {
        margin-left: 10px;
        > div {
          margin-left: 10px;
        }
      }
      .bottom-btn {
        width: 100%;
        justify-content: space-between;
      }
    }
  }
}
