.my-review-product {
  margin-bottom: 1rem;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 1rem;
  .product-my-review {
    display: flex;
    .img-product-review {
      margin-right: 1rem;
      height: 100px;
      width: 100px;
      min-width: 100px;
      min-height: 100px;
      border: 1px solid #b8b8b896;
    }
    .name-product-review {
      color: #000;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 74px;
    }
  }
  .rating-product {
    display: flex;
    align-items: center;
    margin: 1rem 0;
  }
  .form-review-product {
    padding: 1rem;
    background-color: #e9e9e9;
    .review-description {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      textarea {
        padding: 1rem;
        margin-top: 0.5rem;
      }
    }
  }
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .my-review-product {
    .product-my-review {
      .img-product-review {
        min-width: 80px;
        min-height: 80px;
        height: 80px;
        width: 80px;
      }
      .information-product-review {
        .name-product-review {
          font-size: 1rem;
        }
        .quantity-product-review {
          font-size: 0.8rem;
        }
      }
    }
    .rating-product {
      > span {
        display: none;
      }
    }
  }
}
