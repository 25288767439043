.footer-home {
  a {
    color: #fafafa;
    text-decoration: none;
    margin-bottom: 1rem;
    display: block;
    transition: all 0.3s;
    &:hover {
      text-decoration: none;
      color: #db4444;
    }
  }
  background-color: #000;
  padding: 5rem 0 1.5rem;
  .items {
    margin-bottom: 3rem;
    .link-logo {
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      letter-spacing: 0.045rem;
      margin-bottom: 1.5rem;
    }
    .sub-title {
      color: #fafafa;

      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.75rem;
    }
    .content {
      color: #fafafa;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
    .form-send-email {
      border: 1px solid #fafafa;
      border-radius: 4px;
      background-color: transparent;
      padding: 6px 10px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        border: transparent;
        background-color: transparent;
        color: #fafafa;
        font-size: 1rem;
        width: 85%;
        &:focus-visible {
          outline: none;
        }
      }
      .send-icon {
        border: none;
        position: relative;
        i {
          position: absolute;
          right: 0;
          top: -50%;
          transform: translate(0%, -50%);
          padding: 0.8rem;
          font-size: 1rem;
          color: #fafafa;
          transition: all 0.3s ease;
          line-height: 1rem;
        }
        &:hover i {
          color: #db4444;
          transform: translate(4px, -26px);
        }
      }
    }
  }
  .footer-title {
    color: #fafafa;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
    margin-bottom: 1.5rem;
  }
  .account-links {
    margin-bottom: 1.5rem;
    color: #fafafa;
    a {
      display: inline;
      &:first-child {
        margin-right: 4px;
      }
      &:last-child {
        margin-left: 4px;
      }
    }
  }
  .copyright {
    color: #fff;
    opacity: 0.5;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: center;
    border-top: 1px solid;
    padding-top: 1rem;
  }
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
  .footer-home {
    padding: 3rem 0 1.5rem;
  }
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .footer-home .items {
    text-align: center;
    .form-send-email .send-icon i {
      right: -0.8rem;
    }
    .sub-title,
    .footer-title {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
    .content,
    a {
      margin-bottom: 0.8rem;
      font-size: 0.8rem;
    }
    .account-links {
      margin-bottom: 0.8rem;
    }
    .link-logo {
      margin-bottom: 1rem;
    }
  }
}
