.address-dialog {
  .item-edit-address {
    .address-dialog,
    .phone-dialog,
    .name-dialog {
      margin-bottom: 1rem;
      span {
        width: 40%;
        margin-right: 1rem;
      }
      input[type="number"] {
        -moz-appearance: textfield;
        appearance: none;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
      input {
        padding: 6px 10px;
        border-color: #c24d4d;
        border-radius: 4px;
        &:focus-visible {
          border-color: #db4444 !important;
          outline: #db4444;
        }
      }
    }
  }
  .btn-box {
    button {
      padding: 5px 10px;
      border-radius: 0;
      color: #db4444;
      font-size: 0.8rem;
      transition: all 0.3s ease-in;
      &:hover {
        border-color: transparent;
      }
    }
    .btn-abort {
      border: 2px solid #db4444;
      &:hover {
        color: #ffffff;
        background-color: #db4444;
      }
    }
    .btn-save {
      background-color: #db4444;
      border: 2px solid #db4444;
      color: white;
      transition: all 0.3s ease;
    }
  }
}
