.home {
  margin: 3rem 0;
  .home-heading {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 16%;
      top: 0;
      background-color: #0000001e;
      width: 1px;
      height: 100%;
    }
  }
}

// Reposive

/* Mobile - Tablet */
@media (max-width: 1023px) {
  .home {
    margin: 0;
  }
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
}
