.order-item {
  margin-bottom: 1rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
  .order-list-products {
    background-color: white;
    display: flex;
    list-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 1rem;
    margin-bottom: 0;
    .order-item-product .link-item-product {
      display: flex;
      text-decoration: none;
      .img-product {
        border: 1px solid rgba(0, 0, 0, 0.1);
        width: 140px;
        height: 140px;
        img {
          object-fit: cover;
        }
      }
      .content-product {
        margin-left: 1rem;
        .name-product {
          font-size: 1.2rem;
          color: #000;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .classify {
          .classify-sub {
            color: #777777;
            font-size: 1rem;
            margin-right: 8px;
          }
          .classify-name {
            color: #777777;
            font-size: 1rem;
          }
        }
      }
      .quantity-product {
        .quantity-product-sub {
          color: #000;
          font-size: 1rem;
          margin-right: 4px;
        }
        .product-number {
          color: #000;
          font-size: 1rem;
        }
      }
      .return-date {
        color: #db4444;
        border: 1px solid #db4444;
        font-size: 1rem;
        display: inline-block;
        padding: 2px 6px;
        margin-top: 1rem;
      }
    }
  }
  .bottom-item {
    background-color: #fffefb;
    padding: 1rem;
    .bottom-item-heading {
      .order-status {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        .order-status-block {
          display: flex;
          align-items: center;
          .transport {
            color: #42b5a6;
            font-size: 0.9rem;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 0.7rem;
          }
          .info-icon {
            font-size: 1.2rem;
            color: #777777;
          }
        }
        .status {
          font-size: 1rem;
          font-weight: 300;
          color: #db4444;
          text-transform: uppercase;
        }
      }
      .order-price {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .order-price-sub {
          display: flex;
          align-items: center;
          color: #000;
          font-size: 1rem;
          .order-price-icon {
            font-size: 1.2rem;
            margin-right: 4px;
            color: #db4444;
          }
        }
        .order-price-money {
          display: flex;
          align-items: center;
          margin-left: 8px;
          .order-money-sub {
            font-size: 1rem;
            color: #db4444;
            transform: translateY(-6px);
            margin-right: 6px;
          }
          .order-money {
            font-size: 1.5rem;
            color: #db4444;
          }
        }
      }
    }
  }
  .bottom-item-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    a {
      text-decoration: none;
      padding: 0.8rem 3rem;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: transparent;
      background-color: #db4444;
      color: white;
      border-radius: 2px;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}

/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
  .order-item .bottom-item-btn {
    justify-content: end;
  }
}

/* Mobile */
@media (max-width: 739px) {
  .my-order {
    .heading {
      margin-bottom: 1rem;
      .title {
        font-size: 1.2rem;
      }
    }
    .tabs-order {
      margin-bottom: 10px;
      .tabs-list {
        .MuiButtonBase-root {
          width: fit-content;
        }
      }
    }
    .tabs-order-content {
      .order-item {
        margin-bottom: 10px;
        .order-list-products {
          .order-item-product {
            .link-item-product {
              .img-product {
                min-width: 100px;
                min-height: 100px;
                height: 100px;
              }
              .content-product {
                .name-product {
                  font-size: 1rem;
                }
                .quantity-product span,
                .classify span {
                  font-size: 0.8rem;
                }
                .return-date {
                  font-size: 0.6rem;
                }
              }
            }
          }
        }
        .bottom-item {
          .bottom-item-heading {
            .order-status {
              flex-direction: column;
              .order-status-block {
                align-items: start;
                margin-bottom: 1rem;
                .transport {
                  font-size: 0.7rem;
                  .shipping-icon {
                    font-size: 1rem;
                  }
                }
              }
              .status {
                align-self: flex-end;
              }
            }
            .order-price {
              .order-price-sub {
                font-size: 0.8rem;
              }
              .order-price-icon {
                font-size: 1rem;
              }

              .order-price-money {
                .order-money-sub {
                  margin-right: 3px;
                }
                .order-money {
                  font-size: 1.25rem;
                }
              }
            }
          }
          .bottom-item-btn {
            margin-top: 1rem;
            justify-content: space-between;
            a {
              padding: 0.4rem 1rem;
              height: 35px;
              width: 100%;
              &:not(:last-child) {
                margin-right: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
