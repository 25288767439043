.fixed-buy-now {
  // display: none;
  position: fixed;
  right: 0.5rem;
  top: 50%;
  background-color: #db4444;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  z-index: 99;
  transition: all 0.3s ease;
  &:hover {
    color: white;
    opacity: 0.8;
  }
}

.table-cart {
  border-collapse: separate;
  border-spacing: 0 2.5rem;
  margin-bottom: 3rem;
  .table-heading {
    .product {
      padding-left: 2rem;
    }
    .quantity,
    .price,
    .Subtotal {
      text-align: center;
    }
    .delete-button {
      text-align: end;
      padding-right: 2rem;
    }
  }
  .table-content {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    .content-product {
      display: flex;
      align-items: center;
    }
    .img-product {
      min-width: 7rem;
      height: 7rem;
      margin: 16px;
      border-radius: 6px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content-price,
    .content-subtotal,
    .content-quantity,
    .content-delete {
      text-align: center;
    }

    .content-product {
      .title-product {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        color: #000;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
      }
    }
  }
}
.cart-total {
  border-radius: 0.25rem;
  border: 1.5px solid #000;
  padding: 1rem;
  .title-total {
    color: #000;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem;
  }
  .table-total {
    tr {
      td {
        color: #000;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        padding: 1rem 0;
      }
      &:not(:last-child) {
        border-bottom: 1.5px solid #0000006e;
      }
    }
    td:nth-child(2) {
      text-align: end;
    }
  }
  .btn-buy {
    padding: 0.8rem 6rem;
    margin: auto;
    margin-top: 8px;
    display: block;
  }
}
.coupon-code {
  display: flex;
  align-items: center;
  height: 3rem;
  input {
    padding: 0.7rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid #000;
    font-size: 1rem;
    color: #000;
    height: 100%;
    margin-right: 1rem;
    &:focus-visible {
      border-color: #db4444;
      outline: #db4444;
    }
  }
  .apply-coupon {
    padding: 0.8rem 3rem;
    height: 100%;
    margin: 0;
  }
}
button {
  &:focus {
    outline: none;
  }
}
.price-mobile {
  display: none;
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
  .table-cart {
    .table-heading {
      .product {
        padding-left: 0;
      }
      .delete-button {
        padding-right: 0;
      }
    }
  }
  .coupon-code {
    margin-bottom: 2rem;
    input {
      width: 70%;
    }
    .apply-coupon {
      width: 30%;
    }
  }
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .table-cart {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0;
    width: 100%;
    thead {
      display: none;
    }
    .table-heading {
      th {
        font-size: 1rem;
      }
    }
    .table-content {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      td:not(:last-child) {
        margin-bottom: 1rem;
      }
      .content-product {
        flex-direction: column;
        .img-product {
          margin: 10px 0;
        }
      }
      .content-quantity {
        justify-content: center;
      }
    }
  }
  .price-mobile {
    display: inline-block;
  }
  .coupon-code {
    input {
      font-size: 0.8rem;
      width: 60%;
    }
    .apply-coupon {
      width: 40%;
      padding: 0.8rem 0;
      font-size: 0.8rem;
    }
  }
}
