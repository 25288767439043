.page-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  margin-top: 3rem;
  .title-not-found {
    color: #000;
    font-size: 6.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 7.1875rem;
    letter-spacing: 0.20625rem;
    margin-bottom: 3rem;
  }
  .sub-title {
    color: #000;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 3rem;
  }
  .btn-back-home {
    display: inline-flex;
    padding: 1rem 3rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.25rem;
    background: #db4444;
    color: #fafafa;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    text-decoration: none;
  }
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
  .page-not-found {
    height: 50vh;
  }
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .page-not-found {
    .title-not-found {
      font-size: 4.5rem;
      line-height: 2rem;
      margin-bottom: 2rem;
    }
    .sub-title {
      margin-bottom: 2rem;
    }
    .btn-back-home {
      padding: 0.6rem 2rem;
    }
  }
}
