.contact {
  .contact_info {
    flex-direction: column;
    align-items: center;
    .title {
      color: #000;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.5rem;
      margin-bottom: 1rem;
      i {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        background-color: #db4444;
        color: white;
        line-height: 1rem;
        padding: 10px;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 6px;
      }
    }
    .information {
      font-size: 0.875rem;
      line-height: 1.3125rem;
      font-weight: 400;
      color: #000;
    }
    a {
      transition: all 0.3s;
      &:hover {
        opacity: 0.5;
        text-decoration: none;
      }
    }
  }
  .contact_info,
  .contact_form {
    border-radius: 0.5rem;
    padding: 2.5rem 2.1875rem 3.1875rem 2.1875rem;
    box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.05);
  }
  .contact_form {
    padding-bottom: 6rem;
    .btn_send_mess {
      float: right;
      display: flex;
      padding: 0.5rem 2rem;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      border-radius: 0.25rem;
      background-color: #db4444;
      border: none;
      color: white;
      margin-top: 1rem;
    }
  }
}

/* Reponsive */

/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .contact .contact_form .btn_send_mess {
    width: 100%;
  }
}
