.tab-header.Mui-selected {
  color: #db4444 !important;
}
.css-11yukd5-MuiTabs-indicator {
  background-color: #db4444 !important;
}
.my-order {
  .heading {
    margin-bottom: 2rem;
  }
  .tabs-order {
    background-color: white;
    padding-bottom: 0.5rem;
    border-radius: 4px;
    margin-bottom: 1.5rem;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    .tabs-order-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
    }
    .tabs-list {
      > div > div {
        justify-content: space-between;
      }
    }
  }
}
