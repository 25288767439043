.back-to-top {
  position: fixed;
  right: 1rem;
  bottom: 2rem;
  width: 50px;
  height: 50px;
  background-color: #db4444;
  z-index: 999;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  &:hover {
    background-color: #e95a5a;
  }
}
