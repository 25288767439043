.category-product {
  .heading-title {
    font-size: 1.75rem;
    color: #000;
  }
  .sorted {
    font-size: 1rem;
    color: #000;
    .btn-reset-filter {
      height: 43px;
      min-width: unset;
      margin-right: 1rem;
      border-radius: 4px;
    }
  }
  .menu-mobile-icon {
    cursor: pointer;
  }
}
