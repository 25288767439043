.list-products-category {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  position: relative;
}
.img-cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 50%;
  }
  &::after {
    content: "Không Có Sản Phẩm Nào";
    color: #020202;
    font-weight: 500;
    font-size: 1.25rem;
    width: max-content;
  }
  img {
    object-fit: contain;
  }
}
