.search {
  .form-search {
    background-color: #f5f5f5;
    padding: 0.44rem 1.25rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-search-next {
      display: none;
    }
    input {
      background-color: transparent;
      color: #000000;
      border: none;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.125rem;
      &::placeholder {
        color: #000000;
        opacity: 0.5;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      border: none;
    }
  }
}
/* PC */
@media (min-width: 1024px) {
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .search {
    .bg-search {
      background-color: #000000a6;
      position: fixed;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1000;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }
    .bg-search.show {
      opacity: 0.8;
      visibility: visible;
    }
    .form-search {
      margin-left: 0.5rem;
      background-color: transparent;
      position: relative;
      padding: 0;
      .input-search {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-52%);
        background-color: #ffffff;
        opacity: 0;
        z-index: 1001;
        width: 80vw;
        padding: 10px;
        border-radius: 4px;
        overflow: hidden;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        display: flex;
        align-items: center;
        .btn-search-next {
          display: block;
          background-color: transparent;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: -10px;
            top: 0;
            background-color: #0000008e;
            height: 100%;
            width: 1px;
          }
        }
      }

      .input-search.show {
        opacity: 1;
        visibility: visible;
      }
      .search-icon {
        font-size: 1.5rem;
        color: #db4444;
      }
      .input-text {
        color: rgb(0, 0, 0);
        width: 100%;
      }

      .btn-submit-search {
        background-color: transparent;
      }
    }
  }
}
