.product {
  .product-heading {
    background-color: white;
    padding: 1rem;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .image-gallery {
      display: flex;
      height: 60vh;
      gap: 1rem;
      .small-img {
        width: 20%;
        padding-right: 10px;
        overflow-y: scroll;
        &::-webkit-scrollbar-track {
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgb(85, 83, 83);
          border-radius: 10px;
        }
        .img-item {
          cursor: pointer;
          border-radius: 6px;
          overflow: hidden;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
      .big-img {
        width: 80%;
        height: 100%;
        border-radius: 6px;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          cursor: pointer;
        }
        figure {
          width: 100%;
          height: 100%;
          cursor: zoom-in;
        }
      }
    }
    .price_container {
      display: flex;
      justify-content: space-between;

      .current_price {
        color: #db4444;
        font-size: 1.5rem;
      }

      .original_price {
        text-decoration: line-through;
        color: #6b6b6b;
        font-size: 1rem;
      }
    }

    .infomation-product {
      .title-product {
        color: #000;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.5rem;
        letter-spacing: 0.045rem;
        margin-bottom: 1rem;
      }
      .star-reviews {
        display: flex;
        align-items: center;
        margin-bottom: 1.06rem;
        .in-stock {
          color: #0f6;
          font-size: 0.875rem;
          font-weight: 400;
          padding-left: 1rem;
          border-left: 2px solid #00000079;
          margin-left: 1rem;
        }
      }
      .line {
        background-color: #00000079;
        margin: 1rem 0 1.5rem;
      }
      .options {
        display: flex;
        align-items: center;
        color: #000;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem;
        letter-spacing: 0.0375rem;
        margin-bottom: 1rem;
        .option-item {
          margin-left: 1rem;
          border: 1px solid #00000079;
          padding: 4px 10px;
          color: #000;
          border-radius: 3px;
          font-size: 1rem;
          transition: all 0.3s ease;
          &:hover {
            border-color: #db4444;
            color: #db4444;
          }
        }
      }
      .quantity-block {
        display: flex;
        align-items: center;
        .content-quantity {
          margin-right: 1rem;
          button {
            display: flex;
            width: 40px;
            height: 44px;
            justify-content: center;
            align-items: center;
            border-color: rgba(0, 0, 0, 0.5);
            transition: all 0.3s;
            &:first-child {
              border-right: 1px solid rgba(0, 0, 0, 0.5);
              border-radius: 4px 0px 0px 4px;
            }
            &:last-child {
              border-left: 1px solid rgba(0, 0, 0, 0.5);
              border-radius: 0px 4px 4px 0px;
            }
            &:hover {
              background-color: #db4444;
              border-color: #db4444;
              i {
                color: #fff;
              }
            }
          }
          input {
            display: flex;
            height: 44px;
            width: 80px;
            border-color: rgba(0, 0, 0, 0.5);
            color: #000;
            justify-content: center;
            align-items: center;
          }
        }

        .favorite-button {
          padding: 4px;
          justify-content: center;
          align-items: center;
          display: flex;
          width: 44px;
          height: 44px;
          border-radius: 4px;
          color: #000000b4;
          border: 1px solid rgba(0, 0, 0, 0.5);
          &:hover {
            background-color: white;
          }
        }
      }
      .add-buy-block {
        display: flex;
        align-items: center;
        margin-top: 1rem;
      }
    }
  }
  .product-rating,
  .product-description {
    background-color: white;
    padding: 1rem;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    .descript-block {
      box-shadow: none;
    }
    .title {
      font-size: 1.2rem;
      color: #000;
      font-weight: 500;
      background-color: #f5f5f5;
      border-radius: 6px;
      cursor: pointer;
      text-transform: capitalize;
      text-decoration: none;
    }
    .content {
      font-size: 1rem;
      color: #000;
      margin-top: 2rem;
    }
  }
}
/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
  .product {
    .product-heading .image-gallery {
      flex-direction: column-reverse;
      margin-bottom: 2rem;
      .small-img {
        display: flex;
        width: calc(100vw - 4rem);
        overflow-x: scroll;
        .img-item {
          min-width: 50px;
          min-height: 50px;
          margin-right: 10px;
        }
      }
    }

    .add-buy-block {
      flex-direction: column;
      gap: 1rem;
      .cus-btn {
        width: 100%;
      }
    }
    .product-description {
      .content {
        h2 {
          font-size: 1.25rem;
        }
        p {
          font-size: 1rem;
        }
      }
    }
    .product-rating {
      .all-img-reviews {
        margin-top: 1rem;
        .title-img-review {
          font-size: 1.1rem;
        }
        .img-review-contents .items {
          min-width: 87px;
          min-height: 87px;
        }
        .img-review-contents {
          .items:nth-child(n + 4) {
            display: none;
          }
        }
      }
    }
    .reviewer {
      margin-bottom: 1rem;
    }
    .review-content {
      .review-img-lists {
        .items {
          min-width: 87px;
          min-height: 87px;
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }
    }
  }
}
