* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
.main-apps {
  background-color: #f5f5f5;
}
.main_content {
  min-height: calc(100vh - 365px - 130px - 25px);
}
a {
  display: block;
}
img {
  width: 100%;
  height: 100%;
}

.cus-btn {
  position: relative;
  display: inline-block;
  background-color: transparent;
  border: 1px solid #db4444;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  margin-right: 1rem;
  min-width: 150px;
  padding: 10px 20px;
  transition: 0.3s;
}
.cus-btn:hover,
.cus-btn:focus {
  outline: 0 !important;
  outline-offset: 0;
}
.cus-btn::before {
  position: absolute;
  content: "";
  z-index: -1;
}
.button-container {
  background-color: #db4444;
  color: white;
}
.button-outline::before {
  background-color: #db4444;
  transition: 0.3s ease-out;
}
.button-container::before {
  background-color: #fff;
  transition: 0.3s ease-out;
}
.button-outline {
  color: #db4444;
  transition: 0.2s;
  z-index: 1;
}
.button-container {
  color: #fff;
  z-index: 1;
  transition: 0.2s;
}

.button-outline:hover {
  color: rgb(255, 255, 255);
  transition: 0.3s;
} /* 9. hover-slide-right */
.button-container:hover {
  color: #db4444;
  transition: 0.3s;
} /* 9. hover-slide-right */
.button-outline::before,
.button-container::before {
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 0%;
}
.button-outline:hover::before,
.button-container:hover::before {
  width: 100%;
}
.button-container:hover::before {
  background-color: #fff;
}

/* button boder */
/* sử dụng bằng:  btn border-animation */
/* sử dụng bằng:  btn btn-animation */
.btn {
  background: #db4444;
  border: none;
  border-radius: 0;
  color: #ffffff;
  padding: 0.3rem 2.5rem;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.border-animation::before,
.border-animation::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  transition: all 0.5s ease-in-out;
  border: 0px solid transparent;
}
.border-animation::before {
  top: -4px;
  left: -4px;
  border-top: 2px solid #db4444;
  border-left: 2px solid #db4444;
}
.border-animation::after {
  bottom: -4px;
  right: -4px;
  border-bottom: 2px solid #db4444;
  border-right: 2px solid #db4444;
}
.border-animation:hover::before,
.border-animation:hover::after {
  width: calc(100% + 8px);
  height: calc(100% + 8px);
}
.btn-animation {
  border: 2px solid #db4444;
  outline: 2px solid #fff;
  transition: outline-offset 200ms ease;
}
.btn-animation:hover {
  outline: 2px solid #fff;
  outline-offset: -6px;
  color: #ffffff;
}

/* button outline */
/* thêm vào button cần sử dụng: (btn-outline at-the-same-time) */
.btn-outline {
  position: relative;
  display: block;
  background-color: transparent !important;
  color: #db4444 !important;
  border: 2px solid #db4444 !important;
  text-align: center;
  z-index: 3;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0.4rem 3rem;
}
.btn-outline:hover {
  border-color: transparent !important;
}

.btn-outline:before,
.btn-outline:after {
  display: block;
  content: " ";
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.btn-outline:before {
  top: -0.125rem;
  left: 0;
}

.btn-outline:after {
  bottom: 0;
  right: 0;
}

.btn-outline.at-the-same-time:hover:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-top: 0.125rem solid #db4444;
  border-right: 0.125rem solid #db4444;
  transition: width 300ms cubic-bezier(0.07, 0.62, 0.61, 1),
    height 150ms 300ms cubic-bezier(0.07, 0.62, 0.61, 1);
}

.btn-outline.at-the-same-time:hover:after {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-bottom: 0.125rem solid #db4444;
  border-left: 0.125rem solid #db4444;
  transition: width 300ms cubic-bezier(0.07, 0.62, 0.61, 1),
    height 150ms 300ms cubic-bezier(0.07, 0.62, 0.61, 1);
}

.btn-outline.one_after_the_other:hover:before {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-top: 0.125rem solid #db4444;
  border-right: 0.125rem solid #db4444;
  transition: width 150ms cubic-bezier(0.07, 0.62, 0.61, 1),
    height 75ms 150ms cubic-bezier(0.07, 0.62, 0.61, 1);
}

.btn-outline.one_after_the_other:hover:after {
  width: 100%;
  height: 100%;
  opacity: 1;
  border-bottom: 0.125rem solid #db4444;
  border-left: 0.125rem solid #db4444;
  transition: width 150ms 225ms cubic-bezier(0.07, 0.62, 0.61, 1),
    height 75ms 450ms cubic-bezier(0.07, 0.62, 0.61, 1),
    border-left 0ms 225ms linear;
}

/* Mobile - Tablet */
@media (max-width: 1023px) {
}

/* Tablet */
@media (min-width: 740px) and (max-width: 1023px) {
}

/* Mobile */
@media (max-width: 739px) {
}
